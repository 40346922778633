<template>
    <v-alert v-if="organisation" color="lightest" class="pa-3 mt-5">
        <span class="grey--text">{{ link }}</span>
        <copy-button :text="link"></copy-button>
    </v-alert>
</template>

<script>
import CopyButton from "@c/buttons/CopyButton.vue";
export default {
	name: "StaffSurveyLink",
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
		link() {
			return `${window.location.origin}/staff-survey/${this.organisation}`;
		},		
	},
	components: { CopyButton },
};
</script>
